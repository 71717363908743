import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import Button from "../../components/CustomButtons/Button.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import NavPills from "../../components/NavPills/NavPills.js";
import Parallax from "../../components/Parallax/Parallax.js";

import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/bg-small1.png";
import { Box, List } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function ServicesPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="white"
        brand="ALBERTRON INDUSTRIES"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={background} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer
              style={{ color: "#737373", textAlign: "left", paddingLeft: 8 }}
            >
              <div style={{ width: "50%", color: "#ffbd59" }}>
                <Box borderColor={"#ffbd59"} borderBottom={1}>
                  <h2 style={{ marginBottom: 5 }}>Websites</h2>
                </Box>
              </div>
              <div style={{ width: "90%" }}>
                <p
                  style={{
                    textAlign: "left",
                    color: "#737373",
                    marginTop: 2,
                    fontStyle: "italic",
                    fontSize: 12,
                  }}
                >
                  A website is a set of related web pages located under a single
                  domain name. Typically used for presenting information about a
                  particular subject or a group of subjects, such as a business
                </p>
              </div>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ width: "100%" }}>
                  <h3 style={{ marginBottom: 5 }}>Packages</h3>
                  <ul>
                    <li>
                      <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <h4
                          style={{ margin: 5, marginLeft: 0, color: "#ffbd59" }}
                        >
                          Package 1
                        </h4>
                        <p style={{ fontSize: 14 }}>
                          Development Fee: R4000 once-off
                        </p>
                        <p style={{ fontSize: 14 }}>1-4 pages</p>
                      </div>
                    </li>
                    <li>
                      <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <h4
                          style={{ margin: 5, marginLeft: 0, color: "#ffbd59" }}
                        >
                          Package 2
                        </h4>
                        <p style={{ fontSize: 14 }}>
                          Development Fee: R7000 once-off
                        </p>
                        <p style={{ fontSize: 14 }}>5-7 pages</p>
                      </div>
                    </li>
                    <li>
                      <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <h4
                          style={{ margin: 5, marginLeft: 0, color: "#ffbd59" }}
                        >
                          Package 3
                        </h4>
                        <p style={{ fontSize: 14 }}>
                          Development Fee: R10000 once-off
                        </p>
                        <p style={{ fontSize: 14 }}>8-10 pages</p>
                      </div>
                    </li>
                    <li>
                      <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <h4
                          style={{ margin: 5, marginLeft: 0, color: "#ffbd59" }}
                        >
                          Package 4
                        </h4>
                        <p style={{ fontSize: 14 }}>
                          Development Fee: Dependent
                        </p>
                        <p style={{ fontSize: 14 }}>11+ pages</p>
                      </div>
                    </li>
                  </ul>
                  <div style={{ width: "90%" }}>
                    <h3 style={{ marginBottom: 5 }}>Domain</h3>
                    <h5>Price: R150 - R300 once-off</h5>
                    <h5>
                      The domain name will be checked to see if it is avaliable
                      and if it is the price will be communicated.
                    </h5>
                    <p style={{ fontStyle: "italic", fontSize: 13 }}>
                      In the case the domain name is not avaliable or more than
                      R300, this will be communicated.
                    </p>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ width: "100%" }}>
                  <h3 style={{ marginBottom: 5 }}>Hosting</h3>
                  <ul>
                    <li>
                      <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <h4
                          style={{ margin: 5, marginLeft: 0, color: "#ffbd59" }}
                        >
                          Basic Hosting
                        </h4>
                        <p style={{ fontSize: 14 }}>R1000 once-off</p>
                        <div>
                          <ul style={{ fontSize: 12 }}>
                            <li>5 GB SSD Storage</li>
                            <li>UNLIMITED* Traffic</li>
                            <li>Host 5 Domains</li>
                            <li>FREE SSL Certificate</li>
                            <li>5 Database</li>
                            <li>50 Mailboxes</li>
                            <li>Anti-SPAM MailScanner</li>
                            <li>Anti-Virus Scanner</li>
                            <li>Remote Webmail Access</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <h4
                          style={{ margin: 5, marginLeft: 0, color: "#ffbd59" }}
                        >
                          Standard
                        </h4>
                        <p style={{ fontSize: 14 }}>R1800 once-off</p>
                        <div>
                          <ul style={{ fontSize: 12 }}>
                            <li>15 GB SSD Storagee</li>
                            <li>UNLIMITED* Traffic</li>
                            <li>Host 10 Domains</li>
                            <li>FREE SSL Certificate</li>
                            <li>10 Databases</li>
                            <li>150 Mailboxes</li>
                            <li>Anti-SPAM MailScanner</li>
                            <li>Anti-Virus Scanner</li>
                            <li>Remote Webmail Access</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <h4
                          style={{ margin: 5, marginLeft: 0, color: "#ffbd59" }}
                        >
                          Pro
                        </h4>
                        <p style={{ fontSize: 14 }}>R2600 once-off</p>
                        <div>
                          <ul style={{ fontSize: 12 }}>
                            <li>35 GB SSD Storage</li>
                            <li>UNLIMITED* Traffic</li>
                            <li>Host 25 Domains</li>
                            <li>FREE SSL Certificate</li>
                            <li>25 Databases</li>
                            <li>300 Mailboxes</li>
                            <li>Anti-SPAM MailScanner</li>
                            <li>Anti-Virus Scanner</li>
                            <li>Remote Webmail Access</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <h4
                          style={{ margin: 5, marginLeft: 0, color: "#ffbd59" }}
                        >
                          Premier
                        </h4>
                        <p style={{ fontSize: 14 }}>R3000 once-off</p>
                        <div>
                          <ul style={{ fontSize: 12 }}>
                            <li>50 GB SSD Storage</li>
                            <li>UNLIMITED* Traffic</li>
                            <li>Host 50 Domains</li>
                            <li>FREE SSL Certificate</li>
                            <li>50 Databases</li>
                            <li>500 Mailboxes</li>
                            <li>Anti-SPAM MailScanner</li>
                            <li>Anti-Virus Scanner</li>
                            <li>Remote Webmail Access</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ width: "90%" }}>
                  <h3 style={{ marginBottom: 5 }}>Maintenance Plans</h3>
                  <ul>
                    <li>
                      <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <h4
                          style={{ margin: 5, marginLeft: 0, color: "#ffbd59" }}
                        >
                          Base Maintenance
                        </h4>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>
                          R550 per month
                        </p>
                        <p style={{ fontSize: 14 }}>
                          This package is designed to keep the site running
                          smoothly. In the event of any software crashes,
                          updates, or other issues that may cause downtime, we
                          will address them within 24 hours to ensure that the
                          website experiences minimal downtime.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <h4
                          style={{ margin: 5, marginLeft: 0, color: "#ffbd59" }}
                        >
                          Base Retainer
                        </h4>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>
                          R1000 per month
                        </p>
                        <p style={{ fontSize: 14 }}>
                          This package includes base maintenance and a retainer
                          that covers changes to components and pages that
                          already exist on the website.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <h4
                          style={{ margin: 5, marginLeft: 0, color: "#ffbd59" }}
                        >
                          Full Retainer
                        </h4>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>
                          R2500 per month
                        </p>
                        <p style={{ fontSize: 14 }}>
                          This package includes everything mentioned above, as
                          well as any additional development you may require,
                          such as new pages, features, functionality, and
                          components.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

      <div
        style={{ marginTop: 20 }}
        className={classNames(classes.main, classes.mainRaised)}
      >
        <div>
          <div className={classes.container}>
            <GridContainer
              style={{ color: "#737373", textAlign: "left", paddingLeft: 8 }}
            >
              <div style={{ width: "50%", color: "#ffbd59" }}>
                <Box borderColor={"#ffbd59"} borderBottom={1}>
                  <h2 style={{ marginBottom: 5, color: "#ffbd59" }}>
                    Web Applications
                  </h2>
                </Box>
              </div>
              <p
                style={{
                  textAlign: "left",
                  color: "#737373",
                  marginTop: 2,
                  fontStyle: "italic",
                  fontSize: 12,
                  width: "100%",
                }}
              >
                A web application is a full program that runs on a web browser
              </p>

              <div style={{ width: "90%" }}>
                <h4>
                  Please send us a description of the platform you would like
                  built and we will promptly send you a quote
                </h4>
              </div>
            </GridContainer>
          </div>
        </div>
      </div>

      <div
        style={{ marginTop: 20 }}
        className={classNames(classes.main, classes.mainRaised)}
      >
        <div>
          <div className={classes.container}>
            <GridContainer
              style={{ color: "#737373", textAlign: "left", paddingLeft: 8 }}
            >
              <div style={{ width: "50%", color: "#ffbd59" }}>
                <Box borderColor={"#ffbd59"} borderBottom={1}>
                  <h2 style={{ marginBottom: 5, color: "#ffbd59" }}>
                    Mobile Applications
                  </h2>
                </Box>
              </div>
              <div style={{ width: "90%" }}>
                <p
                  style={{
                    textAlign: "left",
                    color: "#737373",
                    marginTop: 2,
                    fontStyle: "italic",
                    fontSize: 12,
                    width: "100%",
                  }}
                >
                  A mobile application is a type of application software
                  designed to run on a mobile device, such as a smartphone or
                  tablet computer
                </p>

                <h4>
                  Please send us a description of the platform you would like
                  built and we will promptly send you a quote
                </h4>
              </div>
            </GridContainer>
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: 20 }}
        className={classNames(classes.main, classes.mainRaised)}
      >
        <div>
          <div className={classes.container}>
            <GridContainer
              style={{ color: "#737373", textAlign: "left", padding: 10 }}
            >
              <div style={{ width: "50%", color: "#ffbd59" }}>
                <Box borderColor={"#ffbd59"} borderBottom={1}>
                  <h2 style={{ marginBottom: 5 }}>Digital Marketing</h2>
                </Box>
              </div>
              {/* <p
                style={{
                  textAlign: "left",
                  color: "#737373",
                  marginTop: 2,
                  fontStyle: "italic",
                  fontSize: 12,
                  width:'100%'
                }}
              >
                A mobile application is a type of application software designed to run on a mobile device, such as a smartphone or tablet computer
              </p> */}
              <div style={{ width: "100%" }}>
                <h4>
                  Contact us and we will outline a plan for you along with a
                  quote
                </h4>
              </div>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
