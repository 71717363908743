import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";

import styles from "../../assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";

import background from "../../assets/images/bg-small1.png";
import { EmojiPeople, Language, PhoneIphone, Web } from "@material-ui/icons";

import { NavLink } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="white"
        routes={dashboardRoutes}
        brand="ALBERTRON INDUSTRIES"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "black",
        }}
        {...rest}
      />
      <Parallax filter image={background}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1>Digitize your Business</h1>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  color: "#d9d9d9",
                }}
              >
                <Language style={{ marginRight: 10 }} />
                <h4>Websites</h4>{" "}
              </div>{" "}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  color: "#d9d9d9",
                }}
              >
                <Web style={{ marginRight: 10 }} />
                <h4>Web Applications</h4>{" "}
              </div>{" "}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  //justifyContent: "center",
                  alignItems: "center",
                  color: "#d9d9d9",
                }}
              >
                <PhoneIphone style={{ marginRight: 10 }} />
                <h4>Mobile Applications</h4>{" "}
              </div>{" "}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  //justifyContent: "center",
                  alignItems: "center",
                  color: "#d9d9d9",
                }}
              >
                <EmojiPeople style={{ marginRight: 10 }} />
                <h4>Digital Marketing</h4>{" "}
              </div>
              <br />
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <NavLink
                  style={{
                    color: "#737373",
                    textDecorationLine: "none",
                    marginRight: 10,
                  }}
                  to={"/startdevelopment"}
                >
                  <Button
                    color="transparent"
                    size="lg"
                    href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ backgroundColor: "#ffbd59" }}
                  >
                    start now
                  </Button>
                </NavLink>
              </div>
            </GridItem>{" "}
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />

          <div>
            {" "}
            <NavLink
              style={{
                color: "#ffbd59",
                textDecorationLine: "none",
                marginRight: 10,
              }}
              to={"/services"}
            >
            <Button
              color="transparent"
              style={{ margin: 20, backgroundColor: "#ffbd59", color:'white' }}
            >
              Find out more
            </Button></NavLink>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
