import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import InfoArea from "../../../components/InfoArea/InfoArea.js";

import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import { EmojiPeople, Language, PhoneIphone, Web } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer
        style={{ justifyContent: window.innerWidth >= 768 ? "center" : "" }}
      >
        <GridItem style={{ width: "90%" }} xs={12} sm={12} md={8}>
          <h2 style={{ color: "#737373" }}>Services</h2>
          <h5 className={classes.description}>
            We take pride in bringing our customer's vision to life. Below are
            more details about our services.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer
          style={{
            justifyContent: window.innerWidth >= 768 ? "space-evenly" : "",
          }}
        >
          <GridItem style={{ width: "90%" }} xs={12} sm={12} md={4}>
            <InfoArea
              title="Websites"
              description="From R4300"
              icon={Language}
              iconColor="#ffbd59"
              vertical
            />
          </GridItem>
          <GridItem style={{ width: "90%" }} xs={12} sm={12} md={4}>
            <InfoArea
              title="Web Application Development"
              description="Please contact us for a quote"
              icon={Web}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{
            marginTop: 20,

            justifyContent: window.innerWidth >= 768 ? "space-evenly" : "",
          }}
        >
          {" "}
          <GridItem style={{ width: "90%" }} xs={12} sm={12} md={4}>
            <InfoArea
              title="Mobile Application Development"
              description="Please contact us for a quote"
              icon={PhoneIphone}
              iconColor="danger"
              vertical
            />
          </GridItem>{" "}
          <GridItem style={{ width: "90%" }} xs={12} sm={12} md={4}>
            <InfoArea
              title="Digital Marketing"
              description="Please contact us for a quote"
              icon={EmojiPeople}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
