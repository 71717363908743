import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";

import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/bg-small1.png";

import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components

import Button from "../../components/CustomButtons/Button.js";
import GridItem from "../../components/Grid/GridItem.js";

import { Language, PhoneIphone, Web } from "@material-ui/icons";
import { Box, Card } from "@material-ui/core";
import unilink from "../../assets/unilink/UNILINK.png";
import c4f from "../../assets/c4f/Coding4Finance.png";
import shfh from "../../assets/shfh/shfh.png";
import ours from "../../assets/ours/ours-loading.gif";
import hoi from "../../assets/hoi/hoiLogoDark.png";
import referu from "../../assets/referu/referu.png";
import coalesce from "../../assets/coalesceed/coalesceEd.jpg";
import jma from "../../assets/images/jma-logo.png";
import lha from "../../assets/images/lha-logo.png";
import ourspace from "../../assets/images/ours-logo-black.png";
import eff from "../../assets/images/eff-logo.jpeg";
import nwfcac from "../../assets/nwfcac/nwfcac.png";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(styles);

const primary = "#ffbd59";

export default function ProjectsPage(props) {
  const classes = useStyles();
  const theme = createMuiTheme({
    palette: "#ffbd59",
    secondary: "#d9d9d9",
  });
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  return (
    <div>
      <Header
        color="white"
        brand="ALBERTRON INDUSTRIES"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax style={{ height: 200 }} small filter image={background} />
      <div
        // style={{ padding: 8 }}
        className={classNames(classes.main, classes.mainRaised)}
      >
        <div style={{ width: "100%" }}>
          <div className={classes.container}>
            <GridContainer
              style={{ color: "#737373", textAlign: "left", width: "100%" }}
            >
              <div style={{ width: "70%", color: "#ffbd59" }}>
                <Box borderColor={"#ffbd59"} borderBottom={1}>
                  <div style={{ alignItems: "center", display: "flex" }}>
                    <h2
                      style={{ marginTop: 10, marginLeft: 8, marginBottom: 5 }}
                    >
                      Websites
                    </h2>
                  </div>
                </Box>
              </div>
              <p
                style={{
                  textAlign: "left",
                  color: "#737373",
                  marginTop: 2,
                  fontStyle: "italic",
                  fontSize: 12,
                  width: "85%",
                  margin: 8,
                  paddingLeft: 10,
                  marginRight: 5,
                }}
              >
                A website is a set of related web pages located under a single
                domain name. Typically used for presenting information about a
                particular subject or a group of subjects, such as a business
              </p>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <NavLink to={"/unilink-entrepreneurs-website"}>
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${unilink})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </NavLink>
              </GridItem>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <NavLink to={"/coding4finance-website"}>
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${c4f})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </NavLink>
              </GridItem>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <a target="_blank" href="https://www.shfh.co.za">
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${shfh})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </a>
              </GridItem>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <a
                  target="_blank"
                  href="https://www.houseofintelligence.co.za/"
                >
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${hoi})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </a>
              </GridItem>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <a target="_blank" href="https://www.jmacademy.co.za/">
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${jma})`,
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundColor: "",
                    }}
                    onClick={() => {}}
                  ></Card>
                </a>
              </GridItem>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <a target="_blank" href="https://www.lighthouseeducation.co.za">
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${lha})`,
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundColor: "#004aad",
                    }}
                    onClick={() => {}}
                  ></Card>
                </a>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

      <div
        style={{ marginTop: 20 }}
        className={classNames(classes.main, classes.mainRaised)}
      >
        <div style={{ width: "100%" }}>
          <div className={classes.container}>
            <GridContainer
              style={{ color: "#737373", textAlign: "left", width: "100%" }}
            >
              <div style={{ width: "70%", color: "#ffbd59" }}>
                <Box borderColor={"#ffbd59"} borderBottom={1}>
                  <h2 style={{ marginTop: 10, marginLeft: 8, marginBottom: 5 }}>
                    Web Applications
                  </h2>{" "}
                </Box>
              </div>
              <p
                style={{
                  textAlign: "left",
                  color: "#737373",
                  marginTop: 2,
                  fontStyle: "italic",
                  fontSize: 12,
                  width: "85%",
                  margin: 8,
                  paddingLeft: 10,
                  marginRight: 5,
                }}
              >
                A web application is a full program that runs on a web browser
              </p>{" "}
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <NavLink to={"/referu-web-app"}>
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${referu})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </NavLink>
              </GridItem>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <NavLink to={"/coding4finance-web-app"}>
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${c4f})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </NavLink>
              </GridItem>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <a target="_blank" href="https://www.thatsours.co.za/">
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${ours})`,
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </a>
              </GridItem>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <NavLink to={"/eff"}>
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${eff})`,
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundColor: "#a60100",
                    }}
                    onClick={() => {}}
                  ></Card>
                </NavLink>
              </GridItem>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <NavLink to={"/ourspace"}>
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${ourspace})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </NavLink>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

      <div
        style={{ marginTop: 20 }}
        className={classNames(classes.main, classes.mainRaised)}
      >
        <div style={{ width: "100%" }}>
          <div className={classes.container}>
            <GridContainer
              style={{ color: "#737373", textAlign: "left", width: "100%" }}
            >
              <div style={{ width: "70%", color: "#ffbd59" }}>
                <Box borderColor={"#ffbd59"} borderBottom={1}>
                  <h2 style={{ marginTop: 10, marginLeft: 8, marginBottom: 5 }}>
                    Mobile Applications
                  </h2>{" "}
                </Box>
              </div>
              <p
                style={{
                  textAlign: "left",
                  color: "#737373",
                  marginTop: 2,
                  fontStyle: "italic",
                  fontSize: 12,
                  width: "85%",
                  margin: 8,
                  paddingLeft: 10,
                  marginRight: 5,
                }}
              >
                A mobile application is a type of application software designed
                to run on a mobile device, such as a smartphone or tablet
                computer
              </p>
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <NavLink to={"/unilink-entrepreneurs-mobile-app"}>
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${unilink})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </NavLink>
              </GridItem>{" "}
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <NavLink to={"/coalesce-ed-mobile-app"}>
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${coalesce})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </NavLink>
              </GridItem>{" "}
              <GridItem
                xs={12}
                sm={6}
                md={3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                }}
              >
                <NavLink to={"/NWFCAC-mobile-app"}>
                  <Card
                    style={{
                      height: 180,
                      width: 160,
                      backgroundImage: `url(${nwfcac})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </NavLink>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: 20 }}
        className={classNames(classes.main, classes.mainRaised)}
      ></div>
      <Footer />
    </div>
  );
}
