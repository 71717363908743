import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";

import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/bg-small1.png";
import WorkSection from "../LandingPage/Sections/WorkSection.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import { useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import { Box, List } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "../../components/CustomButtons/Button.js";
import emailjs from "emailjs-com";

const useStyles = makeStyles(styles);

export default function ContactPage(props) {
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [cellNo, setCellNo] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);
  const classes = useStyles();
  const { ...rest } = props;

  const inputChangeName = (value) => {
    setName(value.target.value);
  };

  const inputChangeT = (value) => {
    setTitle(value.target.value);
  };

  const inputChangeSurname = (value) => {
    setSurname(value.target.value);
  };

  const inputChangeEmail = (value) => {
    setEmail(value.target.value);
  };

  const inputChangeCell = (value) => {
    setCellNo(value.target.value);
  };

  const inputChangeMessage = (value) => {
    setMessage(value.target.value);
  };

  let sendingMessage = {
    name: name,
    surname: surname,
    email: email,
    cellNo: cellNo,
    message: message,
  };

  const sendEmail = (info) => {
    emailjs
      .send(
        "service_ibld2uv",
        "template_dnwxsoi",
        info,
        "user_Wczsp2dfSdQUIqVSWuKEY"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  console.log("Name:", name);
  console.log("Surname:", surname);

  return (
    <div>
      <Header
        color="white"
        brand="ALBERTRON INDUSTRIES"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={background} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          {sent ? (
            <div className={classes.section}>
              <GridContainer  justify="center"
                style={{ width: "100%", paddingLeft: 35, paddingRight: 35,  }}
              >
                <GridItem cs={12} sm={12} md={8} style={{marginTop: 30, marginBottom: 30}}>
                  <div>
                    {" "}
                    <h2 style={{ color: "#737373" }}>
                      Thank you for contacting ALBERTRON INDUSTRIES. You will
                      get a response via email within the next 24 hours.
                    </h2>
                  </div>{" "}
                </GridItem>
              </GridContainer>{" "}
            </div>
          ) : (
            <GridContainer
              style={{ width: "100%", paddingLeft: 35, paddingRight: 35 }}
            >
              {" "}
              <GridItem style={{ justifyContent: "center" }}>
                <div
                  style={{
                    width: "100%",
                    color: "#ffbd59",
                    paddingRight: 10,
                    paddingLeft: 10,
                    flex: 1,
                    display: "flex",
                    // backgroundColor: "red",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h1
                    style={{
                      marginBottom: 14,
                      color: "#ffbd59",
                      // textAlign: "left",
                    }}
                  >
                    Contact Us
                  </h1>
                </div>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <FormControl fullWidth style={{ margin: 5, marginBottom: 15 }}>
                  <InputLabel style={{ fontSize: 14 }}>Name</InputLabel>
                  <Input
                    style={{ color: "#737373" }}
                    onChange={(text) => {
                      inputChangeName(text);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <FormControl fullWidth style={{ margin: 5, marginBottom: 15 }}>
                  <InputLabel style={{ fontSize: 14 }}>Surname</InputLabel>
                  <Input
                    style={{ color: "#737373" }}
                    onChange={(text) => {
                      inputChangeSurname(text);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <FormControl fullWidth style={{ margin: 5, marginBottom: 15 }}>
                  <InputLabel style={{ fontSize: 14 }}>Email</InputLabel>
                  <Input
                    style={{ color: "#737373" }}
                    onChange={(text) => {
                      inputChangeEmail(text);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <FormControl fullWidth style={{ margin: 5, marginBottom: 15 }}>
                  <InputLabel style={{ fontSize: 14 }}>
                    Cellphone number
                  </InputLabel>
                  <Input
                    style={{ color: "#737373" }}
                    onChange={(text) => {
                      inputChangeCell(text);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem style={{ justifyContent: "left" }}>
                <FormControl fullWidth style={{ margin: 5, marginBottom: 15 }}>
                  <InputLabel style={{ fontSize: 14 }}>Message</InputLabel>
                  <Input
                    multiline={true}
                    style={{ color: "#737373" }}
                    onChange={(text) => {
                      inputChangeMessage(text);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem style={{ justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    paddingRight: 10,
                    paddingLeft: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    color="transparent"
                    size="lg"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ backgroundColor: "#ffbd59" }}
                    onClick={() => {
                      console.log("pressed !!!");
                      console.log(
                        "Info:",
                        name,
                        surname,
                        email,
                        cellNo,
                        message
                      );
                      console.log("sending message:", sendingMessage);
                      sendEmail(sendingMessage);
                    }}
                  >
                    Send Message
                  </Button>
                </div>
              </GridItem>{" "}
            </GridContainer>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
