import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";


import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import { MailOutline } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 style={{ color: "#737373" }}>Work With Us</h2>
          <h5 className={classes.description}>
            Please contact us if you are interested in any of our services. We will respond within an hour to set up a meeting.
          </h5>
          <form>
            <GridContainer>
            <div style={{width:'100%', display:"flex", flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
            <MailOutline style={{marginRight:20, color:'#ffbd59'}}/>
<h4 style={{color:'#737373'}}>info@albertron.co.za</h4></div>
          

              {/* <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
              /> */}

            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
