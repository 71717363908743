import {
  createMuiTheme,
  MuiThemeProvider,
  ThemeProvider,
} from "@material-ui/core";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import ContactPage from "./screens/ContactPage/ContactPage";
import LandingPage from "./screens/LandingPage/LandingPage";
import UnilinkWebsite from "./screens/ProjectsPage/UnilinkWebsite";
import ProjectsPage from "./screens/ProjectsPage/ProjectsPage";
import ServicesPage from "./screens/ServicesPage/ServicesPage";
import StartDevPage from "./screens/StartDevPage/StartDevPage";
import SHFHWebsite from "./screens/ProjectsPage/SHFHWebsite";
import C4FWebsite from "./screens/ProjectsPage/C4FWebsite";
import C4FWebApp from "./screens/ProjectsPage/C4FWebApp";
import ReferuWebApp from "./screens/ProjectsPage/ReferuWebApp";
import UnilinkMobileApp from "./screens/ProjectsPage/UnilinkMobileApp";
import CoalesceMobileApp from "./screens/ProjectsPage/CoalesceMobileApp";
import NWFCACMobileApp from "./screens/ProjectsPage/NWFCACMobileApp";
import EffWebApp from "./screens/ProjectsPage/EffWebApp";
import OurspaceWebApp from "./screens/ProjectsPage/OurspaceWebApp";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffbd59",
    },
    secondary: {
      main: "#d9d9d9",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/startdevelopment" component={StartDevPage} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/projects" component={ProjectsPage} />
            <Route path="/services" component={ServicesPage} />
            <Route
              path="/unilink-entrepreneurs-website"
              component={UnilinkWebsite}
            />
            <Route path="/shfh-website" component={SHFHWebsite} />
            <Route path="/coding4finance-website" component={C4FWebsite} />
            <Route path="/coding4finance-web-app" component={C4FWebApp} />
            <Route path="/referu-web-app" component={ReferuWebApp} />
            <Route path="/eff" component={EffWebApp} />
            <Route path="/ourspace" component={OurspaceWebApp} />
            <Route
              path="/unilink-entrepreneurs-mobile-app"
              component={UnilinkMobileApp}
            />
            <Route
              path="/coalesce-ed-mobile-app"
              component={CoalesceMobileApp}
            />
            <Route path="/NWFCAC-mobile-app" component={NWFCACMobileApp} />
          </Switch>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
