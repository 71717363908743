import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
// @material-ui/icons
// import 'bootstrap/dist/css/bootstrap.min.css';
// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/bg-small1.png";
// import Carousel from "react-bootstrap/Carousel";
import GridItem from "../../components/Grid/GridItem.js";
import { Language, PhoneIphone, Web } from "@material-ui/icons";
import { useState } from "react";
import unilink1 from "../../assets/unilink/1.png";
import unilink2 from "../../assets/unilink/2.png";
import unilink3 from "../../assets/unilink/3.png";
import unilink4 from "../../assets/unilink/4.png";
import unilink5 from "../../assets/unilink/5.png";
import unilink6 from "../../assets/unilink/6.png";
import unilink7 from "../../assets/unilink/7.png";
import unilink8 from "../../assets/unilink/8.jpg";
import unilink9 from "../../assets/unilink/9.jpg";
import unilink from "../../assets/unilink/UNILINK.png";
import c4f from "../../assets/c4f/Coding4Finance.png";
import shfh from "../../assets/shfh/shfh.png";
import { NavLink } from "react-router-dom";
import { Card } from "@material-ui/core";

const useStyles = makeStyles(styles);

const primary = "#ffbd59";

export default function SHFHWebsite(props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const classes = useStyles();
  const theme = createMuiTheme({
    palette: "#ffbd59",
    secondary: "#d9d9d9",
  });
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Header
          color="white"
          brand="ALBERTRON INDUSTRIES"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
          {...rest}
        />
        <Parallax  small filter image={background} style={{ justifyContent:"center", display:"flex", paddingTop:40  }}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <GridContainer >
              <GridItem xs={12} sm={12} md={6}>
                <NavLink to={"/unilink-entrepreneurs"}>
                  <Card
                    style={{
                      height: 180,
                      width: 150,
                      backgroundImage: `url(${unilink})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => {}}
                  ></Card>
                </NavLink>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div
          style={{ margin: 10, padding: 0 }}
          className={classNames(classes.main, classes.mainRaised)}
        >
          <div style={{ padding: 0 }}>
            <div
              style={{ padding: 0, paddingTop: 15 }}
              className={classes.container}
            >
              <GridContainer justify="center">
                <GridItem>
                  <div>
                    <Carousel dynamicHeight="true">
                      <div>
                        <img src={unilink1} />
                      </div>
                      <div>
                        <img src={unilink2} />
                      </div>
                      <div>
                        <img src={unilink3} />
                      </div>
                      <div>
                        <img src={unilink4} />
                      </div>
                      <div>
                        <img src={unilink5} />
                      </div>
                      <div>
                        <img src={unilink6} />
                      </div>
                      <div>
                        <img src={unilink7} />
                      </div>
                      <div>
                        <img
                          src={unilink8}
                          style={{ height: "%30", width: "30%" }}
                        />
                      </div>
                      <div>
                        <img
                          src={unilink9}
                          style={{ height: "%30", width: "30%" }}
                        />
                      </div>
                    </Carousel>
                  </div>
                </GridItem>
                <GridItem>
                  <div style={{ marginBottom: 15 }}>
                    <h3
                      style={{ color: "#ffbd59", textDecorationLine: "none" }}
                    >
                      <a
                        href="https://www.unilinkentrepreneurs.com"
                        target="_blank"
                        style={{ color: "#ffbd59", textDecorationLine: "none" }}
                      >
                        unilinkentrepreneurs.com
                      </a>
                    </h3>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
}
