import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";

import Parallax from "../../components/Parallax/Parallax.js";
import GridItem from "../../components/Grid/GridItem.js";
import { Box, List } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "../../components/CustomButtons/Button.js";
import emailjs from "emailjs-com";
import GridContainer from "../../components/Grid/GridContainer.js";
import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/bg-small1.png";
import { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(styles);

export default function StartDevPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [selected, setSelected] = useState({
    website: false,
    webApp: false,
    mobileApp: false,
    consult: false,
  });
  const [websiteInfo, setWebsiteInfo] = useState({
    websiteDomain: "",
    description: "",
    pages: 0,
    ecommerce: false,
  });

  const [webAppInfo, setWebAppInfo] = useState({
    webAppDomain: "",
    webAppDescription: "",
  });

  const [mobileAppInfo, setMobileAppInfo] = useState({
    mobileAppName: "",
    mobileAppDescription: "",
  });

  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [cellNo, setCellNo] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);

  const { website, webApp, mobileApp, consult } = selected;

  const { websiteDomain, description, pages, ecommerce } = websiteInfo;

  const { webAppDomain, webAppDescription } = webAppInfo;

  const { mobileAppName, mobileAppDescription } = mobileAppInfo;

  // const  = (value) => {
  //   setSelected(value.target.value);
  // };

  const setServices = (event) => {
    setSelected({
      ...selected,
      [event.target.name]: event.target.checked,
      consult: false,
    });
  };
  const setConsultation = (event) => {
    setSelected({
      website: false,
      webApp: false,
      mobileApp: false,
      consult: true,
    });
  };

  const inputChangeName = (value) => {
    setName(value.target.value);
  };

  const inputChangeSurname = (value) => {
    setSurname(value.target.value);
  };

  const inputChangeEmail = (value) => {
    setEmail(value.target.value);
  };

  const inputChangeCell = (value) => {
    setCellNo(value.target.value);
  };

  const inputChangeMessage = (value) => {
    setMessage(value.target.value);
  };

  let sendingMessage = {
    name: name,
    surname: surname,
    email: email,
    cellNo: cellNo,
    message: message,
    websiteDomain: websiteDomain,
    websitePages: pages,
    ecommerce: ecommerce,
    websiteDescription: description,
    webAppDomain: webAppDomain,
    webAppDescription: webAppDescription,
    mobileAppName: mobileAppName,
    mobileAppDescription: mobileAppDescription,
  };

  const sendEmail = (info) => {
    emailjs
      .send(
        "service_ibld2uv",
        "template_yut7lah",
        info,
        "user_Wczsp2dfSdQUIqVSWuKEY"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  console.log("Website info:", websiteInfo);
  console.log("Web app info:", webAppInfo);
  console.log("Mobile App info:", mobileAppInfo);
  console.log("Personal Info:", sendingMessage);

  return (
    <div>
      <Header
        color="white"
        brand="ALBERTRON INDUSTRIES"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax style={{ height: 200 }} small filter image={background} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        {sent ? (
          <div className={classes.section}>
            <GridContainer
              justify="center"
              style={{ width: "100%", paddingLeft: 35, paddingRight: 35 }}
            >
              <GridItem
                cs={12}
                sm={12}
                md={8}
                style={{ marginTop: 30, marginBottom: 30 }}
              >
                <div>
                  {" "}
                  <h2 style={{ color: "#737373" }}>
                    Thank you for contacting ALBERTRON INDUSTRIES. You will get
                    a response via email/phone call within the next 24 hours.
                  </h2>
                </div>{" "}
              </GridItem>
            </GridContainer>{" "}
          </div>
        ) : (
          <GridContainer
            justify="center"
            style={{ width: "100%", paddingLeft: 35, paddingRight: 35 }}
          >
            <GridItem style={{ justifyContent: "left" }}>
              <FormControl fullWidth style={{ margin: 5, marginBottom: 15 }}>
                {/* <InputLabel style={{ fontSize: 14 }}>Name</InputLabel>
              <Input
                style={{ color: "#737373" }}
                onChange={(text) => {
                  setServices(text);
                }}
              /> */}
                <h4 style={{ textAlign: "left" }}>
                  Select the services you need:
                </h4>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={website}
                      onChange={setServices}
                      name="website"
                    />
                  }
                  label="Website"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={webApp}
                      onChange={setServices}
                      name="webApp"
                    />
                  }
                  label="Web Application"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mobileApp}
                      onChange={setServices}
                      name="mobileApp"
                    />
                  }
                  label="Mobile Application"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={consult}
                      onChange={setConsultation}
                      name="consult"
                    />
                  }
                  label="Not really sure, I need help breaking down what I need"
                />
              </FormControl>
            </GridItem>

            {website && (
              <Box
                borderColor={"#ffbd59"}
                style={{
                  width: "100%",
                  color: "#ffbd59",
                  borderRadius: 10,
                  marginBottom: 30,
                }}
                border={2}
              >
                <GridContainer
                  style={{
                    color: "#737373",
                    textAlign: "left",
                    paddingLeft: 8,
                    width: "100%",
                    marginBottom: 20,
                  }}
                >
                  {" "}
                  <GridItem style={{ justifyContent: "left" }}>
                    <div
                      style={{
                        color: "#737373",
                        textAlign: "left",
                        paddingLeft: 8,
                        marginBottom: 15,
                      }}
                    >
                      {" "}
                      <h2 style={{ marginBottom: 5, color: "#ffbd59" }}>
                        Website
                      </h2>{" "}
                      {/* <div style={{ color: "#ffbd59" }}>
                      <Box borderColor={"#ffbd59"} borderBottom={1}>
                        {" "}
                      </Box>
                    </div> */}
                    </div>
                  </GridItem>{" "}
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ justifyContent: "left" }}
                  >
                    <FormControl
                      fullWidth
                      style={{ margin: 5, marginBottom: 15 }}
                    >
                      <InputLabel style={{ fontSize: 14 }}>
                        Domain Name
                      </InputLabel>
                      <Input
                        style={{ color: "#737373" }}
                        onChange={(event) => {
                          setWebsiteInfo({
                            ...websiteInfo,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        name="websiteDomain"
                        value={websiteDomain}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem
                    style={{ justifyContent: "left" }}
                    xs={12}
                    sm={12}
                    md={6}
                  >
                    <FormControl style={{ margin: 5, marginBottom: 15 }}>
                      <InputLabel style={{ fontSize: 14 }}>
                        Number of Pages
                      </InputLabel>
                      <Input
                        style={{ color: "#737373" }}
                        onChange={(event) => {
                          setWebsiteInfo({
                            ...websiteInfo,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        name="pages"
                        type="number"
                        value={pages}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ justifyContent: "left" }}
                  >
                    <FormControl
                      // fullWidth
                      style={{
                        margin: 5,
                      }}
                    >
                      <FormControlLabel
                        labelPlacement="start"
                        style={{ margin: 0, padding: 0 }}
                        control={
                          <Checkbox
                            checked={ecommerce}
                            onChange={(event) => {
                              setWebsiteInfo({
                                ...websiteInfo,
                                [event.target.name]: event.target.checked,
                              });
                            }}
                            name="ecommerce"
                          />
                        }
                        label="Is it an ecommerce site?"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ justifyContent: "left" }}>
                    <FormControl
                      fullWidth
                      style={{ margin: 5, marginBottom: 15 }}
                    >
                      <InputLabel style={{ fontSize: 14 }}>
                        Description of the Website
                      </InputLabel>
                      <Input
                        style={{ color: "#737373" }}
                        onChange={(event) => {
                          setWebsiteInfo({
                            ...websiteInfo,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        name="description"
                        multiline
                        rows={3}
                        value={description}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>{" "}
              </Box>
            )}

            {webApp && (
              <Box
                borderColor={"#ffbd59"}
                style={{
                  width: "100%",
                  color: "#ffbd59",
                  borderRadius: 10,
                  marginBottom: 30,
                }}
                border={2}
              >
                <GridContainer
                  style={{
                    color: "#737373",
                    textAlign: "left",
                    paddingLeft: 8,
                    width: "100%",
                    marginBottom: 20,
                  }}
                >
                  {" "}
                  <GridItem style={{ justifyContent: "left" }}>
                    <div
                      style={{
                        color: "#737373",
                        textAlign: "left",
                        paddingLeft: 8,
                        marginBottom: 15,
                      }}
                    >
                      {" "}
                      <h2 style={{ marginBottom: 5, color: "#ffbd59" }}>
                        Web Application
                      </h2>{" "}
                      {/* <div style={{ color: "#ffbd59" }}>
                      <Box borderColor={"#ffbd59"} borderBottom={1}>
                        {" "}
                      </Box>
                    </div> */}
                    </div>
                  </GridItem>{" "}
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ justifyContent: "left" }}
                  >
                    <FormControl
                      fullWidth
                      style={{ margin: 5, marginBottom: 15 }}
                    >
                      <InputLabel style={{ fontSize: 14 }}>
                        Domain Name
                      </InputLabel>
                      <Input
                        style={{ color: "#737373" }}
                        onChange={(event) => {
                          setWebAppInfo({
                            ...webAppInfo,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        name="webAppDomain"
                        value={webAppDomain}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ justifyContent: "left" }}>
                    <FormControl
                      fullWidth
                      style={{ margin: 5, marginBottom: 15 }}
                    >
                      <InputLabel style={{ fontSize: 14 }}>
                        Description of the Web Application
                      </InputLabel>
                      <Input
                        style={{ color: "#737373" }}
                        onChange={(event) => {
                          setWebAppInfo({
                            ...webAppInfo,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        name="webAppDescription"
                        multiline
                        rows={3}
                        value={webAppDescription}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>{" "}
              </Box>
            )}

            {mobileApp && (
              <Box
                borderColor={"#ffbd59"}
                style={{
                  width: "100%",
                  color: "#ffbd59",
                  borderRadius: 10,
                  marginBottom: 30,
                }}
                border={2}
              >
                <GridContainer
                  style={{
                    color: "#737373",
                    textAlign: "left",
                    paddingLeft: 8,
                    width: "100%",
                    marginBottom: 20,
                  }}
                >
                  {" "}
                  <GridItem style={{ justifyContent: "left" }}>
                    <div
                      style={{
                        color: "#737373",
                        textAlign: "left",
                        paddingLeft: 8,
                        marginBottom: 15,
                      }}
                    >
                      {" "}
                      <h2 style={{ marginBottom: 5, color: "#ffbd59" }}>
                        Mobile Application
                      </h2>{" "}
                      {/* <div style={{ color: "#ffbd59" }}>
                      <Box borderColor={"#ffbd59"} borderBottom={1}>
                        {" "}
                      </Box>
                    </div> */}
                    </div>
                  </GridItem>{" "}
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ justifyContent: "left" }}
                  >
                    <FormControl
                      fullWidth
                      style={{ margin: 5, marginBottom: 15 }}
                    >
                      <InputLabel style={{ fontSize: 14 }}>App Name</InputLabel>
                      <Input
                        style={{ color: "#737373" }}
                        onChange={(event) => {
                          setMobileAppInfo({
                            ...mobileAppInfo,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        name="mobileAppName"
                        value={mobileAppName}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ justifyContent: "left" }}>
                    <FormControl
                      fullWidth
                      style={{ margin: 5, marginBottom: 15 }}
                    >
                      <InputLabel style={{ fontSize: 14 }}>
                        Description of the Mobile Application
                      </InputLabel>
                      <Input
                        style={{ color: "#737373" }}
                        onChange={(event) => {
                          setMobileAppInfo({
                            ...mobileAppInfo,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        name="mobileAppDescription"
                        multiline
                        rows={3}
                        value={mobileAppDescription}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>{" "}
              </Box>
            )}

            <GridContainer
              style={{ width: "100%", paddingLeft: 35, paddingRight: 35 }}
            >
              {" "}
              <GridItem style={{ justifyContent: "center" }}>
                <div
                  style={{
                    width: "100%",
                    color: "#ffbd59",
                    paddingRight: 10,
                    paddingLeft: 10,
                    flex: 1,
                    display: "flex",
                    // backgroundColor: "red",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h1
                    style={{
                      marginBottom: 14,
                      color: "#ffbd59",
                      // textAlign: "left",
                    }}
                  >
                    Personal Info
                  </h1>
                </div>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <FormControl fullWidth style={{ margin: 5, marginBottom: 15 }}>
                  <InputLabel style={{ fontSize: 14 }}>Name</InputLabel>
                  <Input
                    style={{ color: "#737373" }}
                    onChange={(text) => {
                      inputChangeName(text);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <FormControl fullWidth style={{ margin: 5, marginBottom: 15 }}>
                  <InputLabel style={{ fontSize: 14 }}>Surname</InputLabel>
                  <Input
                    style={{ color: "#737373" }}
                    onChange={(text) => {
                      inputChangeSurname(text);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <FormControl fullWidth style={{ margin: 5, marginBottom: 15 }}>
                  <InputLabel style={{ fontSize: 14 }}>Email</InputLabel>
                  <Input
                    style={{ color: "#737373" }}
                    onChange={(text) => {
                      inputChangeEmail(text);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <FormControl fullWidth style={{ margin: 5, marginBottom: 15 }}>
                  <InputLabel style={{ fontSize: 14 }}>
                    Cellphone number
                  </InputLabel>
                  <Input
                    style={{ color: "#737373" }}
                    onChange={(text) => {
                      inputChangeCell(text);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem style={{ justifyContent: "left" }}>
                <FormControl fullWidth style={{ margin: 5, marginBottom: 15 }}>
                  <InputLabel style={{ fontSize: 14 }}>Message</InputLabel>
                  <Input
                    multiline={true}
                    style={{ color: "#737373" }}
                    onChange={(text) => {
                      inputChangeMessage(text);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem style={{ justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    paddingRight: 10,
                    paddingLeft: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    color="transparent"
                    size="lg"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ backgroundColor: "#ffbd59" }}
                    onClick={() => {
                      console.log("pressed !!!");
                      console.log(
                        "Info:",
                        name,
                        surname,
                        email,
                        cellNo,
                        message
                      );
                      console.log("sending message:", sendingMessage);
                      sendEmail(sendingMessage);
                    }}
                  >
                    start now
                  </Button>
                </div>
              </GridItem>{" "}
            </GridContainer>
          </GridContainer>
        )}
      </div>
      <Footer />
    </div>
  );
}
