import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
// @material-ui/icons
// import 'bootstrap/dist/css/bootstrap.min.css';
// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/bg-small1.png";
// import Carousel from "react-bootstrap/Carousel";
import GridItem from "../../components/Grid/GridItem.js";
import { Language, PhoneIphone, Web } from "@material-ui/icons";
import { useState } from "react";
import referu1 from "../../assets/referu/1.png";
import referu2 from "../../assets/referu/2.png";
import referu3 from "../../assets/referu/3.png";
import referu4 from "../../assets/referu/4.png";
import referu5 from "../../assets/referu/5.png";
import referu6 from "../../assets/referu/6.png";
import referu7 from "../../assets/referu/7.png";
import unilink from "../../assets/unilink/UNILINK.png";
import c4f from "../../assets/c4f/Coding4Finance.png";
import referu from "../../assets/referu/referu.png";
import ourspace from "../../assets/images/ours-logo-black.png";
import eff from "../../assets/images/eff-logo.jpeg";

import { NavLink } from "react-router-dom";
import { Card } from "@material-ui/core";

const useStyles = makeStyles(styles);

const primary = "#ffbd59";

export default function ReferuWebApp(props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const classes = useStyles();
  const theme = createMuiTheme({
    palette: "#ffbd59",
    secondary: "#d9d9d9",
  });
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Header
          color="white"
          brand="ALBERTRON INDUSTRIES"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
          {...rest}
        />
        <Parallax
          small
          filter
          image={background}
          style={{ justifyContent: "center", display: "flex", paddingTop: 40 }}
        >
          <div
            style={{ backgroundColor: "transparent" }}
            className={classNames(classes.main)}
          >
            <div>
              <div className={classes.container}>
                {window.innerWidth >= 768 ? (
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ marginTop: 10, marginBottom: 20 }}
                    >
                      <NavLink to={"/referu-web-app"}>
                        <Card
                          style={{
                            height: 180,
                            width: 160,
                            backgroundImage: `url(${referu})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                          onClick={() => {}}
                        ></Card>
                      </NavLink>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ marginTop: 10, marginBottom: 20 }}
                    >
                      <NavLink to={"/coding4finance-web-app"}>
                        <Card
                          style={{
                            height: 180,
                            width: 160,
                            backgroundImage: `url(${c4f})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                          onClick={() => {}}
                        ></Card>
                      </NavLink>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ marginTop: 10, marginBottom: 20 }}
                    >
                      <NavLink to={"/eff"}>
                        <Card
                          style={{
                            height: 180,
                            width: 160,
                            backgroundImage: `url(${eff})`,
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundColor: "#a60100",
                          }}
                          onClick={() => {}}
                        ></Card>
                      </NavLink>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ marginTop: 10, marginBottom: 20 }}
                    >
                      <NavLink to={"/ourspace"}>
                        <Card
                          style={{
                            height: 180,
                            width: 160,
                            backgroundImage: `url(${ourspace})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                          onClick={() => {}}
                        ></Card>
                      </NavLink>
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer>
                    <GridItem
                      xs={2}
                      sm={12}
                      md={6}
                      style={{ marginTop: 10, marginBottom: 20 }}
                    >
                      <NavLink to={"/referu-web-app"}>
                        <Card
                          style={{
                            height: 90,
                            width: 130,
                            backgroundImage: `url(${referu})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                          onClick={() => {}}
                        ></Card>
                      </NavLink>
                    </GridItem>
                    <GridItem
                      xs={2}
                      sm={12}
                      md={6}
                      style={{ marginTop: 10, marginBottom: 20 }}
                    >
                      <NavLink to={"/coding4finance-web-app"}>
                        <Card
                          style={{
                            height: 90,
                            width: 130,
                            backgroundImage: `url(${c4f})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                          onClick={() => {}}
                        ></Card>
                      </NavLink>
                    </GridItem>
                    <GridItem
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ marginTop: 10, marginBottom: 20 }}
                    >
                      <NavLink to={"/eff"}>
                        <Card
                          style={{
                            height: 90,
                            width: 130,
                            backgroundImage: `url(${eff})`,
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundColor: "#a60100",
                          }}
                          onClick={() => {}}
                        ></Card>
                      </NavLink>
                    </GridItem>
                    <GridItem
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ marginTop: 10, marginBottom: 20 }}
                    >
                      <NavLink to={"/ourspace"}>
                        <Card
                          style={{
                            height: 90,
                            width: 130,
                            backgroundImage: `url(${ourspace})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                          onClick={() => {}}
                        ></Card>
                      </NavLink>
                    </GridItem>
                  </GridContainer>
                )}
              </div>
            </div>
          </div>
        </Parallax>
        <div
          style={{ margin: 10, padding: 0 }}
          className={classNames(classes.main, classes.mainRaised)}
        >
          <div style={{ padding: 0 }}>
            <div
              style={{ padding: 0, paddingTop: 15 }}
              className={classes.container}
            >
              <GridContainer justify="center">
                <GridItem>
                  <div>
                    <Carousel dynamicHeight="true">
                      <div>
                        <img src={referu1} />
                      </div>
                      <div>
                        <img src={referu2} />
                      </div>
                      <div>
                        <img src={referu3} />
                      </div>
                      <div>
                        <img src={referu4} />
                      </div>
                      <div>
                        <img src={referu5} />
                      </div>
                      <div>
                        <img src={referu6} />
                      </div>
                      <div>
                        <img src={referu7} />
                      </div>
                    </Carousel>
                  </div>
                </GridItem>
                <GridItem>
                  <div style={{ marginBottom: 15 }}>
                    <h3
                      style={{ color: "#ffbd59", textDecorationLine: "none" }}
                    >
                      <a
                        href="https://app.referu.io"
                        target="_blank"
                        style={{ color: "#ffbd59", textDecorationLine: "none" }}
                      >
                        app.referu.io
                      </a>
                    </h3>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
}
